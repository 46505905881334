import toast from 'react-hot-toast';
import { BaseError, ContractFunctionRevertedError } from 'viem';
import { useAccount, usePublicClient, useWalletClient } from 'wagmi';

export const useWrite = () => {

    const client = usePublicClient();
    const account = useAccount();
    const wallet = useWalletClient();

    const ContractWrite = async (contractAddr, useAbi, writeFn, inputData, value = 0, address = false) => {
        return await new Promise(async (resolve, reject) => {
            const loadingToast = toast.loading('Confirm transaction from your wallet');
            try {
                
                const { request } = await client.simulateContract({
                    address: contractAddr,
                    abi: useAbi,
                    functionName: writeFn,
                    args: inputData,
                    account: address || account.address,
                    value: value === 0 ? undefined : value,
                });

                const hash = await wallet.data.writeContract(request);
                toast.dismiss(loadingToast);
                await client.waitForTransactionReceipt( 
                    { hash: hash, confirmations: 3 }
                )
                resolve(hash);

            } catch (err) {
                if (err instanceof BaseError) {
                    const revertError = err.walk(err => err instanceof ContractFunctionRevertedError);
                    if (revertError instanceof ContractFunctionRevertedError) {
                        console.log(revertError.data);                    
                        toast.error(revertError.data?.args[0]);
                    } else {
                        toast.error(err.message);
                    }
                }
                console.log(err);
                toast.dismiss(loadingToast);
                reject(false);
            }
        });
    }

    const SignMessage = async (message, address = false) => {
        return await new Promise(async (resolve, reject) => {
            const loadingToast = toast.loading('Sign message from your wallet app to continue');
            try {
                const signature = await wallet.data.signMessage({ account: address || account.address, message: message });
                toast.dismiss(loadingToast);
                resolve(signature);
            } catch (error) {
                toast.dismiss(loadingToast);
                error.shortMessage && toast.error(error.shortMessage);
                reject(error);
            }
        });
    }
    return {ContractWrite, SignMessage};
}