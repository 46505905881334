import React, { useState } from "react";
import toast from "react-hot-toast";
import { useAccount } from "wagmi";
import { useCallAPI } from "../Hooks/callAPI";
import { ButtonPrimaryGradient } from "../Utility/Buttons";
import AmountReceiver from "./AmountReceiver";

const ApproveReceiver = ({ paymentData, getPayData }) => {

  const callAPI = useCallAPI();
  const account = useAccount();

  const [loading, setLoading] = useState(false);
  const [otp, setOtp] = useState(false);

  const approveReceiver = async () => {
    try {

      setLoading(true);
      
      const data = { 
        payout_id: paymentData.paymentData.payout_id, 
        approved_receiver: account.address,
        otp: otp ? otp : false
      };
      
      await callAPI('payouts/approvereceiver', data);
      await getPayData();
      setLoading(false);

    } catch (error) {
      console.log(error);
      toast.error(error);
      setLoading(false);
    }
  }

  return (
    <div className="">
      {paymentData.paymentData.receiver_type === 'address' ?
        <>
          <AmountReceiver amount={paymentData.paymentData.amount} receiver={paymentData.paymentData.receiver_value} />
          <div className="flex justify-center">
            <ButtonPrimaryGradient loading={loading} disabled={loading} onClick={approveReceiver}>Continue</ButtonPrimaryGradient>
          </div>
        </>
      :
        <>

        </>
      }
    </div>
  );
};

export default ApproveReceiver;
