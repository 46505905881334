import React from "react";
import { useHelpers } from "../Hooks/helpers";

const ProcessingFee = ({ paymentData, token }) => {

  const helper = useHelpers();

  return (
    <div className="px-4 md:px-5 py-3 flex items-center justify-between bg-white border-t border-gray-200 shadow-lg">
      <div>
        <p className="text-[#636363] text-xs font-normal">
          Processing Fee
        </p>
      </div>
      <div>
        <p className="text-primary text-sm font-bold"><helper.AmountToCurrency amount={paymentData.allTokens.find(obj => obj.id === token).fee/100} /></p>
      </div>
    </div>
  );
};

export default ProcessingFee;
