import React from "react";
import logo from "../Assets/logo.png";
import appConfig from "../Utility/AppConfig";

const PoweredBy = () => {

  return (
    <p className="text-center text-[#6F6F6F] text-sm mt-6 opacity-70 hover:opacity-100 duration-300">
      <span>Powered by</span>
      <a href={appConfig.websiteLink} target='blank'><img src={logo} className='w-[80px] mx-auto mt-2' /></a>
    </p>
  );
};

export default PoweredBy;