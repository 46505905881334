import { Button } from "@material-tailwind/react";
import React, { useEffect, useState } from "react";
import { AlertTriangle } from "react-feather";
import toast from "react-hot-toast";
import { useParams } from "react-router-dom";
import { useAccount, useChainId, useSwitchChain } from "wagmi";
import AmountReceiver from "../Components/AmountReceiver";
import ApproveReceiver from "../Components/ApproveReceiver";
import ConnectBtn from '../Components/ConnectBtn';
import Footer from "../Components/Footer";
import Logo from "../Components/Logo";
import MerchantDetails from '../Components/MerchantDetails';
import PoweredBy from '../Components/PoweredBy';
import ProcessingFee from '../Components/ProcessingFee';
import StatusCompleted from '../Components/StatusCompleted';
import StatusConfirming from '../Components/StatusConfirming';
import StatusExpired from '../Components/StatusExpired';
import StatusFailed from '../Components/StatusFailed';
import Support from '../Components/Support';
import { useCallAPI } from "../Hooks/callAPI";
import { useWrite } from "../Hooks/writeContract";
import payoutsAbi from "../Utility/ABI/payouts.json";
import { ButtonGrayGradient, ButtonPrimaryGradient } from "../Utility/Buttons";
import { ChainLight } from "../Utility/Chains";
import { Container, LoadingData, Viewport } from "../Utility/Utility";
import CancelRedirect from "../Components/CancelRedirect";

const Payout = () => {

  const callAPI = useCallAPI();
  const params = useParams();
  const account = useAccount();
  const switchChain = useSwitchChain();
  const currChainId = useChainId();
  const writeContract = useWrite();

  const [paymentData, setPaymentData] = useState(false);
  const [chain, setChain] = useState(false);
  const [token, setToken] = useState(false);
  const [status, setStatus] = useState(false);
  const [loading, setLoading] = useState(false);

  let statusInterval;
  useEffect(() => {
    //get the payment ID data
    getPayData();

    //Create interval for getting payment status
    getPaymentsStatus();

    statusInterval = setInterval(() => {
      getPaymentsStatus();
    }, 5000);
    return () => {
        clearInterval(statusInterval);
    };

  }, []);

  const getPayData = async () => {
    try {
      const res = await callAPI('payouts/getpayout/' + params.id);
      setPaymentData(res);
    } catch (error) {
      console.log(error);
    }
  }
  
  const handlePayout = async () => {
    
    try {

      setLoading(true);
      const res = await callAPI('payouts/getpayoutparams/' + params.id, { tokenId: token });

      const payRes = await writeContract.ContractWrite(res.contract, payoutsAbi, 'Dex3Payout', res.payload, res.value);
      await callAPI('transactions/create', { type: 'payout', chain_id: chain, hash: payRes, id: params.id });
      setStatus('received');      
      setLoading(false);

    } catch (error) {
      console.log(error);
      typeof error === 'string' && toast.error('Internal error');
      setLoading(false);
    }

  }
  
  const getPaymentsStatus = async () => {
    try {
      const res = await callAPI('payouts/getpayoutstatus/' + params.id);
      setStatus(res);
      if(res !== 'received' && res !== 'created') {
        res === 'completed' && await getPayData();
        clearInterval(statusInterval);
      }
    } catch (error) {
      clearInterval(statusInterval);
    }
  }

  return (
    <Viewport>
      <Container>
        {paymentData ? 
        <div className="min-h-screen py-8 flex flex-col justify-center">
          <Logo logo={paymentData.merchantData.logo} />
          {account.isConnected &&
            <ConnectBtn />
          }
          <div className="pay max-w-[544px] w-full bg-[#FAF9FF] rounded-[15px] overflow-hidden mx-auto">
            <MerchantDetails paymentData={paymentData} type='Claiming from' />
            {status === 'created' && 
              <>
                <div className="px-4 md:px-5 text-center space-y-8 py-8">
                  {paymentData.paymentData.approved_receiver === null ? 
                    <ApproveReceiver paymentData={paymentData} getPayData={getPayData} />
                  :
                    <div>
                      <h6 className="text-[#636363] text-base font-normal mb-7">
                        Choose Method to Claim from
                      </h6>
                      <div className="flex flex-wrap justify-center gap-3">
                        {paymentData.allTokens.map((e, i) => {
                          return (
                            <div key={i} onClick={() => { setToken(e.id); setChain(e.chain_id); }}>
                              <ChainLight
                                icon={e.logo}
                                name={e.name}
                                isActive={e.id === token}
                              />
                            </div>
                          )
                        })}
                      </div>
                      {token && 
                        <>
                          {account.isConnected ? 
                            <div className="text-center mt-10 flex justify-center">
                              {account.address.toLowerCase() === paymentData.paymentData.receiver_value.toLowerCase() ?
                                <>
                                  {currChainId === chain ?
                                    <ButtonPrimaryGradient loading={loading} disabled={loading} onClick={handlePayout}>Proceed to Claim</ButtonPrimaryGradient>
                                  :
                                    <ButtonGrayGradient onClick={() => switchChain.switchChain({chainId: chain})}>Switch Chain</ButtonGrayGradient>
                                  }
                                </>
                              :
                                <Button color="red" variant="outlined" size="sm" className="animate-pulse flex gap-2 items-center"><AlertTriangle size={15} />Switch to receiver address</Button>
                              }
                            </div>
                          :
                            <div className="text-center mt-10 flex justify-center">
                              <w3m-button />
                            </div>
                          }
                        </>
                      }
                      <div className="mt-10">
                        <AmountReceiver amount={paymentData.paymentData.amount} receiver={paymentData.paymentData.approved_receiver} />
                      </div>
                    </div>
                  }
                </div>
                {token && 
                  <ProcessingFee paymentData={paymentData} token={token} />
                }
              </>
            }

            {status === 'received' && 
              <StatusConfirming type='Payout' />
            }
            {status === 'completed' && 
              <StatusCompleted paymentData={paymentData} type='Payout' />
            }
            {status === 'failed' && 
              <StatusFailed paymentData={paymentData} type='Payout' />
            }
            {status === 'expired' && 
              <StatusExpired paymentData={paymentData} type='Payout' />
            }
            {paymentData.paymentData.cancel_redirect !== null && status === 'created' && <CancelRedirect paymentData={paymentData} />}
          </div>
          <Support paymentData={paymentData} />
          <PoweredBy />
          <Footer />
        </div>
        :
          <LoadingData />
        }
      </Container>
    </Viewport>
  );
};

export default Payout;