import React from "react";
import {
  Route,
  Routes
} from "react-router-dom";
import Error from "./Pages/Error";
import Pay from "./Pages/Pay";
import Payout from "./Pages/Payout";

export default function AppRouter() {
  return (
    <Routes>
        <Route path="/pay/:id" element={<Pay />}></Route>
        <Route path="/payout/:id" element={<Payout />}></Route>
        <Route path="*" element={<Error />} />
    </Routes>
  );
}