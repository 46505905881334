import React from "react";
import { X } from "react-feather";

const CancelRedirect = ({ paymentData }) => {

  return (
    <div className="px-4 md:px-5 py-5 text-center bg-[#323232]">
      <p className="text-[#cbcbcb] text-sm cursor-pointer">
        <a href={paymentData.paymentData.cancel_redirect} className="hover:text-primary flex justify-center gap-1 items-center transition duration-200">
          <X size={18} /> Cancel and go back to {paymentData.merchantData.name}? Click here
        </a>
      </p>
    </div>
  );

};
export default CancelRedirect;
