import React from "react";
import appConfig from "../Utility/AppConfig";

const Footer = () => {

  return (
    <p className="text-center text-[#6F6F6F] text-xs mt-6 opacity-70 hover:opacity-100 duration-300">
      <span>v{appConfig.version}</span>
    </p>
  );
};

export default Footer;