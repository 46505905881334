import React from "react";
import { Link } from "react-router-dom";

const Logo = ({ logo }) => {
  return (
    <div className="flex items-center justify-center pt-4 pb-10">
      <Link className="logo inline-block">
        <img crossOrigin="anonymous" className="block w-[150px]" src={logo} alt="Logo" />
      </Link>
    </div>
    
  );
};

export default Logo;
