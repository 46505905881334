import { usePublicClient } from 'wagmi';

export const useRead = () => {

    const client = usePublicClient();

    const read = async (contractAddr, abi, method, params) => {
        return await new Promise(async (resolve, reject) => {
            try {
                const res = await client.readContract({
                    address: contractAddr,
                    abi: abi,
                    functionName: method,
                    args: params
                });
                resolve(res);
            } catch (error) {
                reject(error);
            }
        });
    }

    return { read };
}