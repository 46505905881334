import { Chip } from '@material-tailwind/react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Copy, ExternalLink } from 'react-feather';
import toast from 'react-hot-toast';
import { NumericFormat } from 'react-number-format';

export const useHelpers = () => {

    //Trim an address
    const trimAddress = ({ address, copy = false, scanURL = false, firstShow = 5, secondShow = 3 }) => {
        
        if(!address) {
            return (<></>);
        }
        const fullAddress = address;

        const short1  = fullAddress.slice(0, firstShow);
        const short2  = fullAddress.slice(fullAddress.length-secondShow, fullAddress.length);
        const trimmedAddr = short1 + '...' + short2;

        if(copy === false) {
            return trimmedAddr;
        } else {
            return (
                <>
                    {trimmedAddr}
                    {copy && <CopyToClipboard onCopy={() => toast.success('Copied!')} text={address}><Copy className='inline-block ml-1' size="13" /></CopyToClipboard>}
                    {scanURL && <a target="_blank" href={scanURL}><ExternalLink className='inline-block ml-1' size="13" /></a>}
                </>
            )
        }
        
    }

    //Convert a amount to readable format
    const AmountToCurrency = ({ amount, decimals = 4, prefix = "$", suffix = '' }) => {
        return (
            <NumericFormat value={amount} displayType="text" allowLeadingZeros allowNegative={true} prefix={prefix} suffix={suffix} thousandsGroupStyle="thousand" decimalScale={decimals} />
        );
    }

    //Convert timestamp to data
    const ProcessDate = (timestamp, opString = false ) => {
        const date = new Date(timestamp*1000);
        const humanData = +date.getDate()+
                "/"+(date.getMonth()+1)+
                "/"+date.getFullYear()+
                " "+date.getHours()+
                ":"+date.getMinutes()+
                ":"+date.getSeconds();

        if(opString) {
            return humanData;
        } else {
            return (
                <>
                {humanData}
                </>
            )
        }
    }
    
    const renderStatusChip = ({ status }) => {
        switch (status) {
            //Campaign status
            case "created":
                return <div className='flex justify-center'><Chip size='sm' color='amber' variant='outlined' value={status} className='capitalize' /></div>
                break;

            case "received":
                return <div className='flex justify-center'><Chip size='sm' color='purple' variant='gradient' value={status} className='capitalize' /></div>
                break;

            case "completed":
                return <div className='flex justify-center'><Chip size='sm' color='green' variant='gradient' value={status} className='capitalize' /></div>
                break;

            case "failed":
                return <div className='flex justify-center'><Chip size='sm' color='red' variant='gradient' value={status} className='capitalize' /></div>
                break;

            case "expired":
                return <div className='flex justify-center'><Chip size='sm' color='red' variant='outlined' value={status} className='capitalize' /></div>
                break;

            default:
                return <div className='flex justify-center'><Chip size='sm' color='green' variant='gradient' value={status} className='capitalize' /></div>
                break;

        }
    }

    return {
        renderStatusChip, 
        trimAddress, 
        ProcessDate, 
        AmountToCurrency, 
    };
    
}

