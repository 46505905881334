import React from "react";
import { useHelpers } from "../Hooks/helpers";

const MerchantDetails = ({ paymentData, type }) => {

  const helper = useHelpers();

  return (
    <div className="px-4 md:px-5 py-5 flex items-center shadow-lg justify-between bg-primary">
      <div>
        <p className="text-lighter text-sm font-normal">
          {type} {paymentData.merchantData.name}
        </p>
        <strong className="block text-light text-base font-bold">
          {paymentData.paymentData.productName}
        </strong>
        <span className="block text-lighter text-[11px] font-normal">
          Order #{paymentData.paymentData.order_id}
        </span>
      </div>
      <div>
        <p className="text-lighter text-base font-normal leading-tight text-right">
          Amount
        </p>
        <strong className="text-light font-bold text-4xl leading-[1.1] flex justify-end">
          <span className="inline-block text-xl">$</span><helper.AmountToCurrency amount={paymentData.paymentData.amount/100} prefix="" />
        </strong>
      </div>
    </div>
  );
};

export default MerchantDetails;
