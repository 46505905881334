import { ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { Button } from "@material-tailwind/react";
import React from "react";

const StatusFailed = ({ paymentData, type }) => {

  return (
    <div className="text-center px-4 md:px-5 mt-10 pb-8">
      <div className="flex justify-center"><ExclamationTriangleIcon className="text-red-400 w-[55px]" /></div>
      <h5 className="text-red-400 text-xl font-bold mt-3 mb-3">
        {type} Failed
      </h5>
      <p className="text-[#777777] text-sm mb-7 max-w-[250px] mx-auto">
        Please close this page and return to {paymentData.merchantData.name} and contact support for more details.
      </p>
      {paymentData.paymentData.cancel_redirect !== null &&
        <a href={paymentData.paymentData.cancel_redirect}><Button color="red" variant="gradient" size="lg" className="">Go to {paymentData.merchantData.name}</Button></a>
      }
    </div>
  );
};

export default StatusFailed;
