import React from "react";

const Support = ({ paymentData }) => {

  return (
    <div className="px-4 py-5 text-center ">
      <p className="text-[#A4A4A4] text-sm font-bold help">
        <a href={paymentData.merchantData.supportUrl} target='blank' className="hover:text-primary transition duration-200">
          Need help? Click here
        </a>
      </p>
    </div>
  );
};

export default Support;
