import { Button } from '@material-tailwind/react';
import React from 'react';
import appConfig from '../Utility/AppConfig';

const Error = () => {

    return (
      <>
        <section class="flex justify-center items-center h-screen p-16 bg-gray-900">
          <div className='text-center'>
            <h2 class="animate-pulse font-extrabold text-6xl sm:text-9xl text-green-600 dark:text-gray-100">
              404
            </h2>
            <p class="text-xl sm:text-2xl text-gray-400 mb-10">Sorry, we couldn't find this page.</p>
            <a href={appConfig.websiteLink}><Button color='green'>Back to home</Button></a>
          </div>
        </section>
      </>
    );
};

export default Error;
