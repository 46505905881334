import React from "react";

const StatusConfirming = ({ type }) => {

  return (
    <div className="text-center px-4 md:px-5 mt-10 pb-8">
      <span className="loader"></span>
      <h5 className="text-primary text-xl font-bold mt-3 mb-3">
        Confirming {type}
      </h5>
      <p className="text-[#777777] text-sm">
        Your transaction is in progress. Your {type} <br />
        will be confirmed automatically
      </p>
    </div>
  );
};

export default StatusConfirming;
