import bgViewportTrans from "../Assets/bg-viewport-trans.png";
import { BarLoader, BounceLoader, CircleLoader, ClipLoader, SquareLoader } from 'react-spinners';
// import emptyImg from '../Assets/emptyRec.png';

export const Viewport = ({ children }) => {
  return (
    <div
      className="min-h-screen bg-[#111111] bg-cover bg-center flex flex-col"
      style={{ backgroundImage: `url(${bgViewportTrans})` }}
    >
      {children}
    </div>
  );
};

export const Container = ({ children }) => {
  return (
    <div className="w-full max-w-full px-4 md:px-6  lg:px-8">{children}</div>
  );
};

export const ContentBox = ({ children }) => {
  return (
    <div className="bg-[#353535] p-4 rounded-lg_next">{children}</div>
  );
};

export const LoadingData = ({ fullHeight = true }) => {
  return (
      <div className={`${fullHeight ? 'grid place-items-center py-[50px] sm:py-[100px]' : 'm-auto'}`}>
        <BounceLoader
          color='#52B86E'
          loading={true}
          size={40}
          aria-label="Loading"
          data-testid="loader"
        />
      </div>
  )
}

export const ContentLoading = ({ ...props }) => {
  return (
      <div {...props}>
        <ClipLoader
          color='#52B86E'
          speedMultiplier={0.8}
          loading={true}
          size={30}
          aria-label="Loading"
          data-testid="loader"
        />
      </div>
  )
}

export const EmptyRecords = ({ title, description, buttonHandler = false, fullHeight = false }) => {
  return (
    <div className={`grid place-items-center ${fullHeight ? 'h-screen' : 'py-[50px] sm:py-[100px]'}`}>
      <div className="max-w-[250px] text-center p-5 bg-primary  shadow-[0_2px_2px_0px_rgb(0,0,0,0.15)] rounded-3xl">
        {/* <img className="w-[100px] sm:w-[150px] block mx-auto my-2" src={emptyImg} /> */}
        <p className="text-darker font-inria-sans font-bold text-lg leading-5 my-2 mt-5">{title}</p>
        <p className="text-darker/80 text-sm leading-4 mt-1">{description}</p>
        {buttonHandler && <button className="bg-gradient-two px-5 py-2 rounded-[5px] font-bold text-sm mt-4" onClick={buttonHandler.onClick}>{buttonHandler.text}</button>}
      </div>
    </div>
  )
}