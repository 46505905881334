import React from "react";
import { useHelpers } from "../Hooks/helpers";

const AmountReceiver = ({ amount, receiver }) => {

  const helper = useHelpers();

  return (
    <>
      <h6 className="text-[#636363] text-base font-normal mb-1">
        <helper.AmountToCurrency amount={amount/100} /> will be sent to this address
      </h6>
      <h5 className="text-green-400 text-xs sm:text-sm font-bold mb-5">
        {receiver}
      </h5>
    </>
  );
};

export default AmountReceiver;
