import { CheckBadgeIcon } from "@heroicons/react/24/outline";
import React from "react";
import { useHelpers } from "../Hooks/helpers";
import { Button } from "@material-tailwind/react";

const StatusCompleted = ({ paymentData, type }) => {

  const helper = useHelpers();
  
  return (
    <>
    <div className="text-center px-4 md:px-5 mt-10 pb-8">
      <div className="flex justify-center"><CheckBadgeIcon className="text-primary w-[55px]" /></div>
      <h5 className="text-primary text-xl font-bold mt-3 mb-3">
        Successfully {type === 'Payout' ? 'Claimed' : 'Paid'} <helper.AmountToCurrency amount={paymentData.paymentData.amount/100} />
      </h5>
      <p className="text-[#777777] text-sm mb-7 max-w-[250px] mx-auto">
        Your {type} is successfully processed. You can now return to {paymentData.merchantData.name}
      </p>
      {paymentData.paymentData.success_redirect === null ?
        <p className='text-gray-600 border border-gray-500 rounded-10 px-4 py-2 inline-block text-xs font-bold'>You can close this page and go back to {paymentData.merchantData.name}</p>
      :
        <a href={paymentData.paymentData.success_redirect}><Button color="green" variant="gradient" size="lg" className="">Go to {paymentData.merchantData.name}</Button></a>
      }
    </div>
    <div className="p-4 mt-8 text-xs text-dark bg-white border-t">
        <div className="flex justify-between border-b pb-4">
          <p>Tx Hash</p>
          <p className="font-bold text-primary"><helper.trimAddress address={paymentData.paymentData.hash} copy /></p>
        </div>
        <div className="flex justify-between border-b py-4">
          <p>{type === 'Payout' ? 'Payout' : 'Payment'} ID</p>
          <p className="font-bold text-primary">{paymentData.paymentData.payment_id || paymentData.paymentData.payout_id}</p>
        </div>
        <div className="flex justify-between py-4 pb-0">
          <p>Order ID</p>
          <p className="font-bold text-primary">{paymentData.paymentData.order_id}</p>
        </div>
      </div>
    </>
  );
};

export default StatusCompleted;
