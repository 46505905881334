import React from "react";

const ConnectBtn = () => {
  return (
    <div className='flex justify-center pb-7'>
      <w3m-button />
    </div>
  );
};

export default ConnectBtn;
